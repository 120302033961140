p {
    text-align: justify;
    margin: auto;
    max-width: 80%;
    color: rgba(0,0,0,.8);
    font-size: 1em;
    padding-bottom: 1.5rem;
}

a {
    color: #268bd2;
}

img {
    max-height: 20ch;
}

.P-top {
    padding-top: 1.5rem;
    border-top: 1px solid black;
}

h1 {
    text-align: left;
    margin: auto;
    max-width: 80%;
    font-family: 'Rock Salt', sans-serif;
    line-height: 4rem;
    padding-bottom: 2rem;
}

.H-turn {
    display: block;
    padding: 0;
    writing-mode: vertical-rl;
    float: left;
    font-family: 'Rock Salt', sans-serif;
    color: #000;
    transform: rotate(0.5turn);
    font-size: 1.9em;
    margin: 0 0 0 -.25rem;
    line-height: 1.1rem;
    white-space: nowrap;
}

.Right {
    float: right;
    margin: 0 -.25rem 0 0;
    transform: rotate(0turn);
}

.C-width {
    position: relative;
    text-align: center;
    display: block;
    margin: auto;
    max-width: 900px;
    padding: 1.5rem 2rem 1.5rem 2rem;
}

.Center {
    padding-top: 1em;
    display: block;
    margin: auto;
    max-width: 80%;
    padding-bottom: 1.5rem;
}

.Button-blue {
    background-color: rgb(255, 254, 250);
    border: none;
    color: #268bd2;
    padding: .78571em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    box-shadow: 0 0 0 2px rgba(0,0,0,.4);
    margin-bottom: .5rem;
    border-radius: 4px;
    transition: all .25s linear;
    cursor: pointer;
}

.Button-blue:hover {
    color: #1b1c1d;
    box-shadow: 0 0 0 2px #1b1c1d;
    transition: all .5s linear;
}

.References {
  border-top: 1px solid grey;
  padding: 1rem;
  text-align: left;
  font-size: 70%;
  color: grey;
}