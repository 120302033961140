.App {
  font-family: 'Roboto';
  text-align: center;
  background-color: rgb(255, 254, 250);
  font-size: 1.5em;
  min-width: 500px;
  padding: 10px 10px 10px 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-column {
  display: block;
  margin: auto;
  font-size: large;
  max-width: 900px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
