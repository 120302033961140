body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Roboto';
  text-align: center;
  background-color: rgb(255, 254, 250);
  font-size: 1.5em;
  min-width: 500px;
  padding: 10px 10px 10px 10px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-column {
  display: block;
  margin: auto;
  font-size: large;
  max-width: 900px;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.H-width {
    display: block;
    margin: auto;
    max-width: 1200px;
    padding-bottom: 1rem;
}
p {
    text-align: justify;
    margin: auto;
    max-width: 80%;
    color: rgba(0,0,0,.8);
    font-size: 1em;
    padding-bottom: 1.5rem;
}

a {
    color: #268bd2;
}

img {
    max-height: 20ch;
}

.P-top {
    padding-top: 1.5rem;
    border-top: 1px solid black;
}

h1 {
    text-align: left;
    margin: auto;
    max-width: 80%;
    font-family: 'Rock Salt', sans-serif;
    line-height: 4rem;
    padding-bottom: 2rem;
}

.H-turn {
    display: block;
    padding: 0;
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
    float: left;
    font-family: 'Rock Salt', sans-serif;
    color: #000;
    -webkit-transform: rotate(0.5turn);
            transform: rotate(0.5turn);
    font-size: 1.9em;
    margin: 0 0 0 -.25rem;
    line-height: 1.1rem;
    white-space: nowrap;
}

.Right {
    float: right;
    margin: 0 -.25rem 0 0;
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
}

.C-width {
    position: relative;
    text-align: center;
    display: block;
    margin: auto;
    max-width: 900px;
    padding: 1.5rem 2rem 1.5rem 2rem;
}

.Center {
    padding-top: 1em;
    display: block;
    margin: auto;
    max-width: 80%;
    padding-bottom: 1.5rem;
}

.Button-blue {
    background-color: rgb(255, 254, 250);
    border: none;
    color: #268bd2;
    padding: .78571em 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    box-shadow: 0 0 0 2px rgba(0,0,0,.4);
    margin-bottom: .5rem;
    border-radius: 4px;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
    cursor: pointer;
}

.Button-blue:hover {
    color: #1b1c1d;
    box-shadow: 0 0 0 2px #1b1c1d;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

.References {
  border-top: 1px solid grey;
  padding: 1rem;
  text-align: left;
  font-size: 70%;
  color: grey;
}
.Footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 3rem 0 2rem 0;
}

.Button-grey {
    background-color: rgb(255, 254, 250);
    border: none;
    color: rgba(0,0,0,.7);
    padding: .6em 1.2em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    box-shadow: 0 0 0 2px rgba(0,0,0,.4);
    margin: 0 .2em .5em .2em;
    border-radius: 4px;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
    cursor: pointer;
}

.Button-grey:hover {
    color: #1b1c1d;
    box-shadow: 0 0 0 2px #1b1c1d;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}
