.Footer {
  display: flex;
  justify-content: center;
  padding: 3rem 0 2rem 0;
}

.Button-grey {
    background-color: rgb(255, 254, 250);
    border: none;
    color: rgba(0,0,0,.7);
    padding: .6em 1.2em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    box-shadow: 0 0 0 2px rgba(0,0,0,.4);
    margin: 0 .2em .5em .2em;
    border-radius: 4px;
    transition: all .25s linear;
    cursor: pointer;
}

.Button-grey:hover {
    color: #1b1c1d;
    box-shadow: 0 0 0 2px #1b1c1d;
    transition: all .5s linear;
}